import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getService, angularize } from 'reactInAngular';
import Numbers from "../../../../utilities/number.utilities";
import Valuation from "../../../../utilities/valuation.utils";
import Dates from "../../../../utilities/date.utilities";
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import {Tooltip} from '@mui/material';
import ScaleChart from '../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import {dateToQueryParam} from '../../../../utilities/navigation_state.utilities';


const EngagementHIBox = ({ to, dateGrouping }) => {
    const [loading, setLoading] = useState(true);
    const [hi, setHi] = useState(null);
    const [hiColor, setHiColor] = useState('');
    const [variation, setVariation] = useState(null);
    const [globalBenchmark, setGlobalBenchmark] = useState(null);
    const [benchmarkDifference, setBenchmarkDifference] = useState(null);
    const [quality, setQuality] = useState('NO_DATA');
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);

    const $translate = getService('$translate');
    const HIService = getService('HIService');
    const ErrorSvrc = getService('ErrorSvrc');

    const getHI = () => {
        setLoading(true);
        const toFormatted = Dates.lastDayOfMonth(to);
        const params = { to: toFormatted, grouping: dateGrouping };

        HIService.stats.get(params, (err, stats) => {
            setLoading(false);
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    setHasEnoughActiveEmployees(false);
                } else {
                    console.error('ERROR getting Stats', err);
                }
            } else {
                const seriesData = stats && stats.periodResults;

                setHi(Numbers.roundNumber(seriesData.periodIndex));
                setHiColor(Valuation.valuationColor(seriesData.valuation));
                setVariation(Numbers.roundNumber(seriesData.periodIndexVariation, 1));
                setGlobalBenchmark(Numbers.roundNumber(stats.globalBenchmark, 1));
                setBenchmarkDifference(Numbers.roundNumber(Numbers.roundNumber(seriesData.periodIndex) - stats.globalBenchmark, 1));
                setQuality(seriesData.quality);
            }
        });
    };

    useEffect(() => {
        getHI();
    }, [to, dateGrouping]);

    const getVariationBenchmarkText = (key, isVariation) => {

        let keys;
        let change;
        if (isVariation) {
            keys = { previous: Numbers.roundNumber(hi - variation, 1), variation: variation };
            change = variation;
        } else {
            keys = { benchmark: globalBenchmark, difference: benchmarkDifference };
            change = benchmarkDifference;
        }

        if (change === 0) {
            return $translate.instant(key + '_NEUTRAL', keys);
        } else if (change > 0) {
            return $translate.instant(key + '_POSITIVE', keys);
        } else {
            return $translate.instant(key + '_NEGATIVE', keys);
        }
    };

    const slices = [{
        color: Valuation.valuationColor('BAD'),
        range: [0, 33],
        label: $translate.instant('HI_SLICE_FIRST')
    }, {
        color: Valuation.valuationColor('WARNING'),
        range: [33, 66],
        label: $translate.instant('HI_SLICE_SECOND')
    }, {
        color: Valuation.valuationColor('EXCELLENT'),
        range: [66, 100],
        label: $translate.instant('HI_SLICE_THIRD')
    }];


    const renderContent = () => {
        return (
            <HappyLoading loading={loading}>
                {!hasEnoughActiveEmployees && (
                    <div className="row m-t-xs">
                        <div className="col-xs-12">
                            <EmptyState
                                image="images/anonymous.png"
                                small={true}
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE')}
                            />
                        </div>
                    </div>
                )}

                {hasEnoughActiveEmployees && quality === 'NO_DATA' && (
                    <div className="row m-t-xs">
                        <div className="col-xs-12">
                            <div className="big-number-container" style={{ color: hiColor }}>
                                <span className="big-number-no-data">{$translate.instant('NO_DATA')}</span>
                            </div>
                            <p className="small-style">{$translate.instant('HI_NO_DATA')}</p>
                        </div>
                    </div>
                )}


                {hasEnoughActiveEmployees && quality !== 'NO_DATA' && (
                    <div className="row same-height">
                        <div className="col-xs-12 col-sm-6">
                            <div className="big-number-container" style={{ color: hiColor }}>
                                <span className="big-number">{hi}</span>
                                <span className="big-number-sub">pts</span>
                            </div>
                            <ScaleChart slices={slices} value={hi}></ScaleChart>

                        </div>
                        <div className="col-xs-12 col-sm-6 border-left space-evenly">
                            <p className="small-style" dangerouslySetInnerHTML={{ __html: getVariationBenchmarkText('ENGAGEMENT_HI_COMPANY_COMPARISON', true) }} />
                            <p className="small-style" dangerouslySetInnerHTML={{ __html: getVariationBenchmarkText('ENGAGEMENT_HI_BENCHMARK_COMPARISON', false) }} />
                        </div>
                    </div>
                )}
            </HappyLoading>
        );
    }

    return (
        <section className="hi_box white-bg">


            <div className="row">
                <div className="col-xs-12 col-sm-9">
                    <h2 className="big-style">
                        {$translate.instant('HAPPINESS_INDEX')}
                    </h2>
                    {!loading && (
                        <>
                            <p className="mini-style warm-grey m-t-xs">
                                {$translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF', { days: moment(to).format(Dates.getDateFormatFromGrouping(dateGrouping)) })}
                            </p>
                            {['GOOD', 'NORMAL', 'WARNING'].includes(quality) && (
                                <Tooltip title={$translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION')}>
                                    <p
                                        className="mini-style underline"
                                        dangerouslySetInnerHTML={{__html: $translate.instant('REPRESENTATIVE_SAMPLE')}}
                                    />
                                </Tooltip>
                            )}
                            {quality === 'LOW' && (
                                <Tooltip title={$translate.instant('SCORE_LOW_QUALITY_DESCRIPTION')}>
                                    <p
                                        className="mini-style underline"
                                        dangerouslySetInnerHTML={{__html: $translate.instant('NO_REPRESENTATIVE_SAMPLE')}}
                                    />

                                </Tooltip>
                            )}
                        </>
                    )}


                </div>
                <div className="col-xs-12 col-sm-3 text-right">
                    {!loading && (
                        <a href={`engagement/detail/hi?to=${dateToQueryParam(to)}`}>{$translate.instant('ENGAGEMENT_VIEW_DETAIL')}</a>
                    )}
                </div>
            </div>

            {renderContent()}


        </section>
    );
};

EngagementHIBox.propTypes = {
    to: PropTypes.any.isRequired,
    dateGrouping: PropTypes.string.isRequired,
};

angularize(EngagementHIBox, 'hfEngagementHiBox', angular.module('happyForceApp'), {
    to: '<',
    dateGrouping: '<'
});

export default EngagementHIBox;
