import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';


import Numbers from '../../../../../utilities/number.utilities';
import Valuation from '../../../../../utilities/valuation.utils';
import Dates from '../../../../../utilities/date.utilities';
import Charts from '../../../../../utilities/charts.utilities';
import EngagementDetailCharts from '../../../engagement_detail.charts';
import {APISettings} from '../../../../../shared/services/migrated/axiosWrapper';
import HfImpactMark from '../../impact_mark/impact_mark.component';
import {Tooltip} from '@mui/material';
import {ScoreName} from '../../../../../shared/new-components/hf-score-name/hfScoreName';
import HappyLoading from '../../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';
import {dateToQueryParam} from '../../../../../utilities/navigation_state.utilities';
import HFTag from 'root/app/shared/new-components/hf-tag/hf-tag'; // Assuming you have this component

const EngagementScoresSummaryRow = ({ score, to, dateGrouping }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        id: '',
        name: '',
        image: '',
        status: '',
        filtered: false,
        quality: 'NO_DATA',
        value: 0,
        valuationColor: '',
        variation: 0,
        from: '',
        lastResult: 0,
        sparkChart: null
    });

    const [hiImpact, setHiImpact] = useState(0);
    const [enpsImpact, setEnpsImpact] = useState(0);
    const [noData, setNoData] = useState(false);
    const [status, setStatus] = useState('');
    const [noEnoughEmployees, setNoEnoughEmployees] = useState(false);
    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const CompanySvrc = getService('CompanySvrc');
    const ScoresV2Service = getService('ScoresV2Srvc');


    const computeBenchmark = (results, filtered) => {
        let benchmarkResults = [];
        let benchmarkAverage = 0;
        if (results) {
            let benchmarkSum = 0;
            let benchmarkCount = 0;
            results.forEach(result => {
                if (filtered && result.companyBenchmark > 0) {
                    benchmarkResults.push(Numbers.roundNumber(result.companyBenchmark, 1));
                    benchmarkSum += result.companyBenchmark;
                    benchmarkCount++;
                } else if (result.globalBenchmark > 0) {
                    benchmarkResults.push(Numbers.roundNumber(result.globalBenchmark, 1));
                    benchmarkSum += result.globalBenchmark;
                    benchmarkCount++;
                }
            });
            if (benchmarkCount > 0) {
                benchmarkAverage = Numbers.roundNumber(benchmarkSum / benchmarkCount, 1);
            } else {
                benchmarkAverage = 0;
            }
        }
        return {
            benchmarkResults: benchmarkResults,
            benchmarkAverage: benchmarkAverage
        };
    };

    const scoreSparkLineEvolution = (data, benchmarkData, benchmarkLabel) => {

        Charts.initializeDateFormats();

        let dataMin;
        let dataMax;
        let seriesData = [];
        let benchmarkSeriesData = [];

        data.forEach((point, index) => {
            if (!dataMin || dataMin > point[1]) {
                dataMin = point[1];
            }
            if (!dataMax || dataMax < point[1]) {
                dataMax = point[1];
            }
            seriesData.push({
                x: Dates.fromAPIFormat(point[0], APISettings.apiDateFormat).getTime() + Charts.oneDayOffset,
                y: Numbers.roundNumber(point[1], 1),
                z: benchmarkData.benchmarkResults[index]
            });
            benchmarkSeriesData.push({
                x: Dates.fromAPIFormat(point[0], APISettings.apiDateFormat).getTime() + Charts.oneDayOffset,
                y: benchmarkData.benchmarkResults[index]
            });
        });

        const min = benchmarkData.benchmarkAverage < dataMin ? benchmarkData.benchmarkAverage - 0.1 : dataMin - 0.1;
        const max = benchmarkData.benchmarkAverage > dataMax ? benchmarkData.benchmarkAverage + 0.1 : dataMax + 0.1;

        return {
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'Helvetica',
                    color: '#333',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStyle: 'normal'
                },
                height: 66,
                marginLeft: 2,
                marginRight: 2,
                marginTop: 2,
                marginBottom: 2
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            yAxis: {
                visible: true,
                max: max,
                min: min,
                gridLineWidth: 0,
                gridLineDashStyle: 'ShortDot',
                gridLineColor: '#EEEEEE'
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                type: 'datetime',
                alignTicks: true,
                endOnTick: false,
                minorGridLineWidth: 1,
                minorTickLength: 1,
                minorTicks: true,
                minorGridLineDashStyle: 'ShortDot',
                minorGridLineColor: '#EEEEEE',
                showFirstLabel: true,
                showEmpty: true,
                visible: true,
                lineWidth: 1,
                lineColor: '#E2E7E9',
                gridLineWidth: 0,
                gridLineColor: '#EEEEEE',
                gridLineDashStyle: 'ShortDot',
                tickWidth: 0
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            colors: [
                '#7cb5ec'
            ],
            pane: {
                background: []
            },
            responsive: {
                rules: []
            },
            tooltip: {
                shared: true,
                enabled: true,
                padding: 4,
                backgroundColor: '#ffffff',
                borderColor: '#4A90E2',
                useHTML: false,
                xDateFormat: Charts.getDateFormatFromDateGrouping(dateGrouping),
                headerFormat: '<span style=\"font-size: 10px\">{point.key}: <b>{point.y}</b>' +
                    '</span><br/><span style=\"font-size: 10px\">' + benchmarkLabel + ': <b>{point.z}</b></span>',
                pointFormat: ''
            },
            series: [
                {
                    name: 'spark',
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'Solid',
                    lineWidth: 2,
                    zones: EngagementDetailCharts.scoreZones,
                    data: seriesData
                },
                {
                    name: 'spark',
                    color: '#4A90E2',
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'dash',
                    lineWidth: 1,
                    data: benchmarkSeriesData
                }
            ]
        };
    };





    useEffect(() => {


        if (score) {
            setLoading(true);
            const params = {
                to: Dates.lastDayOfMonth(to),
                grouping: dateGrouping
            };


            ScoresV2Service.stats.get(score.id, params, (err, result) => {
                // Component initialization logic
                const chartResults = [];
                if (err) {
                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        setNoEnoughEmployees(true);
                        setNoData(false);
                    } else {
                        setNoData(true);
                        ErrorSvrc.showErrorModal(err);
                    }

                } else {
                    if (score && result.results && result.results.length > 0) {

                        setStatus(score.status);

                        const lastResult = result.results.slice(-1)[0];
                        const newData ={
                            value: Numbers.roundNumber(lastResult.result, 1),
                            quality: lastResult.quality,
                            valuationColor: Valuation.valuationColor(lastResult.valuation),
                            variation: Numbers.roundNumber(lastResult.variation, 1),
                            from: Dates.fromAPIFormat(lastResult.from, APISettings.apiDateFormat),
                        };


                        setHiImpact(lastResult.hiImpact);
                        setEnpsImpact(lastResult.enpsImpact);

                        const previousResultIndex = result.results.length - 2;
                        if (previousResultIndex >= 0) {
                            newData.lastResult = Numbers.roundNumber(result.results[previousResultIndex].result, 1);
                        } else {
                            newData.lastResult =0;
                        }


                        // Check if there are results with data
                        const allNoData = result.results.every(result => {return result.quality === 'NO_DATA';});
                        setNoData(allNoData);

                        if (!allNoData) {
                            // Otherwise, build the sparkline
                            result.results.forEach(result => {
                                chartResults.push([result.from, result.result]);
                            });



                            CompanySvrc.getUserCompany((err, company) => {
                                let benchmarkName;
                                if (err || !score.filtered) {
                                    benchmarkName = $translate.instant('Benchmark');
                                } else {
                                    benchmarkName = $translate.instant('Avg.') + company.name;
                                }
                                const benchmarkData = computeBenchmark(result.results, result.filtered);
                                const sparkChartConfig = scoreSparkLineEvolution(chartResults, benchmarkData, benchmarkName, dateGrouping);
                                newData.sparkChart = sparkChartConfig;
                                setData(newData);
                            });
                        }



                        setNoEnoughEmployees(false);
                    } else {
                        setNoData(true);
                    }
                    setLoading(false);
                }

            });
        }


    }, [score, to, dateGrouping]);


    const qualityDesc = () => {
        if (data.quality === 'NO_DATA') {
            return $translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION');
        } else if (data.quality === 'LOW') {
            return $translate.instant('SCORE_LOW_QUALITY_DESCRIPTION');
        } else {
            return $translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION');
        }
    };

    function getContent() {

        if (noEnoughEmployees) {
            return (
                <div className="col-sm-6 col-xs-12">
                    <EmptyState
                        image="images/anonymous.png"
                        small={true}
                        message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                    />
                </div>
            );
        } else if (noData) {
            return (
                <div className="col-sm-6 col-xs-12">
                    <EmptyState
                        image="images/no_scores.svg"
                        small={true}
                        message={$translate.instant('NO_DATA')}
                    ></EmptyState>
                </div>
            );
        } else {
            return (
                <>
                    <div className="col-sm-6 col-xs-12">
                        <HappyLoading loading={loading} noMessage={true} skeleton={3}>
                            {data.sparkChart && (
                                <HighchartsReact highcharts={Highcharts} options={data.sparkChart}/>
                            )}
                        </HappyLoading>

                    </div>
                    <div className="col-sm-3 hidden-xs">
                        <div>
                            <div className="mini-style text-center">
                                {$translate.instant('ENGAGEMENT_IMPACT_HI')}
                            </div>
                            <div className="m-t-sm">
                                <HfImpactMark level={hiImpact} color={data.valuationColor} loading={loading}/>
                            </div>
                        </div>
                        <div className="m-t-sm">
                            <div className="mini-style text-center">
                                {$translate.instant('ENGAGEMENT_IMPACT_ENPS')}
                            </div>
                            <div className="m-t-sm">
                                <HfImpactMark level={enpsImpact} color={data.valuationColor} loading={loading}/>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <div className="p-b-new-md">
            <div className="row">
                <div className="col-xs-12">
                    <a href={`engagement/detail/score/${score.id}?to=${dateToQueryParam(to)}`}><ScoreName scoreId={score.id}/></a>
                </div>
            </div>
            <div className="row content-row">
                <div className="col-sm-3 col-xs-12 score_number">
                    <HappyLoading loading={loading} noMessage={true}>
                        {!noData && data.value > 0 && (
                            <div className="big-style p-h-xs" style={{color: data.valuationColor}}>
                                {data.value} <span className="sub-index">pts</span>
                            </div>
                        )}

                        {noData && (
                            <div className="big-style p-h-xs" style={{color: data.valuationColor}}>
                                {$translate.instant('SCORE_NO_DATA_SHORT')}
                            </div>
                        )}


                        <Tooltip
                            title={<div dangerouslySetInnerHTML={{
                                __html: $translate.instant('ENGAGEMENT_SCORES_POPOVER',
                                    {from: moment(data.from).format(Dates.getDateFormatFromGrouping(dateGrouping)), lastResult: data.lastResult, quality: qualityDesc()})
                            }}/>
                            }>
                            {data.quality === 'NO_DATA' && status != 'DISABLED' && (
                                <p className="mini-style item-labe underlinel"
                                    dangerouslySetInnerHTML={{__html: $translate.instant('NO_DATA')}}/>
                            )}

                            {data.quality === 'NO_DATA' && status === 'DISABLED' && (
                                <HFTag status={'warning'}
                                    text={$translate.instant('SCORE_DISABLED', {result: 1})}></HFTag>
                            )}

                            {(data.quality === 'GOOD' || data.quality === 'NORMAL' || data.quality === 'WARNING') && (
                                <p className="mini-style item-label underline"
                                    dangerouslySetInnerHTML={{__html: $translate.instant('REPRESENTATIVE_SAMPLE')}}/>
                            )}

                            {data.quality === 'LOW' && (
                                <p className="mini-style item-label underline"
                                    dangerouslySetInnerHTML={{__html: $translate.instant('NO_REPRESENTATIVE_SAMPLE')}}/>
                            )}
                        </Tooltip>


                    </HappyLoading>
                </div>

                {getContent()}



            </div>
        </div>
    );

};

EngagementScoresSummaryRow.propTypes = {
    score: PropTypes.object,
    to: PropTypes.instanceOf(Date).isRequired,
    dateGrouping: PropTypes.string,
};

angularize(EngagementScoresSummaryRow, 'hfEngagementScoresSummaryRow', angular.module('happyForceApp'), {
    score: '<',
    to: '<',
    dateGrouping: '<'
});

export default EngagementScoresSummaryRow;
