'use strict';
import async from 'async';
import FeedbackReportTypeModal from '../components/feedback_report_type_modal/feedback_report_type_modal.component';

function FeedbackCommentsCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, Comments, $translate, $state, Analytics, ErrorSvrc, Reports, CompanySvrc, anchorSmoothScroll, SegmentsFilterService, $location, $intercom, FeedbackReportTypeModal) {
    'ngInject';

    var _this = this;

    this.$onInit = function () {

        _this.dateFrom = Filters.startDate();
        _this.dateFromEnabled = true;

        _this.dateTo = Filters.endDate();
        _this.dateToEnabled = true;

        _this.loadingData = true;
        _this.currentCommentPage = 1;
        _this.totalComments = 0;
        _this.exportingExcelReport = false;
        _this.order_by = $translate.instant('RECENT');
        _this.filters = null;
        _this.groups = Filters.groups() || [];
        _this.leyendRatings = [];
        _this.hasEnoughActiveEmployees = true;
        _this.filterByComment;

        // State parameters
        _this.commentsOrder = $stateParams.commentsOrder || null;
        _this.classifiedStatus = $stateParams.classifiedStatus || null;
        _this.hasComments = $stateParams.hasComments === 'true' || false;
        _this.hasEmployeeReplies = $stateParams.hasEmployeeReplies === 'true' || false;
        _this.hasCompanyReply = $stateParams.hasCompanyReply === 'true' || false;
        _this.hasConversation = $stateParams.hasConversation === 'true' || false;
        _this.selectedFeebackTypes = $stateParams.selectedFeebackTypes || ['INFORMATION', 'CONGRATULATION', 'SUGGESTION', 'CRITICISM'];
        _this.privated = $stateParams.privated === 'true' || false;
        _this.searchText = $stateParams.searchText || null;
        _this.activedTags = $stateParams.activedTags || [];
        _this.activedRatings = $stateParams.activedRatings || [1, 2, 3, 4];
        _this.hasReplies = $stateParams.activedRatings || null;
        _this.hasSignature = $stateParams.hasSignature || false;
        _this.hideFilters = false;

        _this.replyId = null;
        if ($stateParams.replyId) {
            _this.replyId = $stateParams.replyId;
        }

        _this.hierarchyId = [];

        _this.popover_stats = {
            totalOther: 0
        };

        _this.dateFormat = $translate.instant('DATE_FORMAT');
        if (_this.dateFormat === 'DATE_FORMAT')
            _this.dateFormat = Settings.defaultDateFormat;

        if ($stateParams.hierarchyLevel && $stateParams.hierarchyLevel != 'top') {
            _this.hierarchyId.push($stateParams.hierarchyLevel);
        }

        _this.polarOptions = {
            with: '100px',
            height: '100px'
        };

        _this.header = {
            title: $translate.instant('COMMENTS'),
            subtitle: $translate.instant('COMMENTS_SUBTITLE')
        };

        if ($stateParams.commentId) {
            _this.header.title = $translate.instant('COMMENTS_DETAIL');
            _this.header.arrow = {
                action: function () {
                    _this.replyId = null;
                    $state.go('feedback.comments');
                }
            };
            _this.hideFilters = true;
        }
        ;

        // Monitorize variables;
        var pageArgs = ['dateFrom', 'dateFromEnabled', 'dateTo', 'dateToEnabled', 'hierarchyId', 'filters', 'groups', 'filterByComment'];
        var lastExecution;
        pageArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (newVal === oldValue) return;
                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                _this.loadComments();
            }, true);
        });

        // Monitorize filters
        var filterArgs = ['searchText',
            'commentsOrder',
            'classifiedStatus',
            'hasEmployeeReplies',
            'hasCompanyReply',
            'hasConversation',
            'selectedFeebackTypes',
            'privated',
            'hasSignature'];

        filterArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (typeof newVal === 'undefined' || newVal === oldValue) return;
                Analytics.trackEvent('comments', 'filter_by_others');
                _this.loadComments();
            });
        });

        // Monitorize order by
        $scope.$watch(angular.bind(_this, function () {
            return _this.commentsOrder;
        }), function (newVal, oldValue) {

            if (typeof newVal === 'undefined' || newVal === oldValue) return;
            var ORDER_BY = newVal || 'RECENT';
            if (ORDER_BY === 'INAPPROPRIATE')
                ORDER_BY = 'REPORTED_COMMENT';

            _this.order_by = $translate.instant(ORDER_BY);
        });

        _this.initialize = function () {
            var stream = [];
            _this.loadingData = true;
            _this.openReplies = false;

            stream.push(function (next) {
                CompanySvrc.getUserCompanyConfig(function (err, companyConfig) {
                    if (err) {
                        console.log('Error updating company config');
                        _this.feedbackTypeEnabled = false;
                        return next(err);
                    } else {
                        if (companyConfig.feedbackTypes && companyConfig.feedbackTypes.length > 0) {
                            _this.feedbackTypeEnabled = true;
                        } else {
                            _this.feedbackTypeEnabled = false;
                        }
                        next();
                    }
                });
            });

            stream.push(function (next) {
                HIService.get(function (err, hiQuestion) {
                    if (err) {
                        console.error('Error loading question', err);
                        return next(err);
                    }

                    _this.question = hiQuestion;
                    _this.leyendRatings = hiQuestion.values;

                    return _this.loadComments(next);
                });
            });

            async.parallel(stream, function (err, data) {
                _this.loadingData = false;
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                }
            });
        };

        _this.loadComments = function (callback) {//filterString, textToSearch, order, classifiedStatus) {
            _this.loadingComments = true;
            var params = prepareParams();

            // Set the pagination to the params
            params.fromComment = (_this.currentCommentPage - 1) * 10;
            params.toComment = 10;
            var commentStream = [];
            commentStream.push(function (next) {
                //Getting Comment stats
                _this.loadCommentsStats = true;
                _this.params = params;
                Comments.stats.get(params, function (err, commentStats) {
                    _this.loadCommentsStats = false;
                    if (err) {
                        return next(err);
                    }

                    _this.commentStats = commentStats;
                    _this.commentsInPeriod = commentStats.comments;
                    _this.commentsInPeriodVariation = commentStats.commentsVariation;
                    _this.commentsLikes = commentStats.likes;
                    _this.commentsDislikes = commentStats.dislikes;
                    _this.commentsInappropriates = commentStats.inappropiates;
                    _this.commentsMarkedAsInappropriate = commentStats.markedAsInappropriate;
                    _this.loadCommentsStats = false;
                    _this.stats = commentStats;

                    if (commentStats.feedbackTypeCount) {
                        _this.stats.totalSuggestions = commentStats.feedbackTypeCount.SUGGESTION || 0;
                        _this.stats.totalCriticism = commentStats.feedbackTypeCount.CRITICISM || 0;
                        _this.stats.totalInformation = commentStats.feedbackTypeCount.INFORMATION || 0;
                        _this.stats.totalCongratulations = commentStats.feedbackTypeCount.CONGRATULATION || 0;
                        _this.popover_stats.totalOther = commentStats.feedbackTypeCount.OTHER || 0;
                    } else {
                        _this.popover_stats.totalOther = 0;
                    }

                    next();
                });
            });

            commentStream.push(function (next) {

                var totalBlockedParams = angular.copy(params);
                delete totalBlockedParams.from;
                delete totalBlockedParams.to;
                totalBlockedParams.statTypes = ['REFEREED'];


                Comments.stats.get(totalBlockedParams, function (err, commentStats) {
                    _this.loadCommentsStats = false;
                    if (err) {
                        return next(err);
                    }

                    _this.totalBlockedComments = commentStats.refereed;

                    next();
                });
            });


            // Get comments
            commentStream.push(function (next) {
                _this.question.comments = [];

                // And get the comments
                Comments.get(params, function (employeeComments) {
                    _this.hasEnoughActiveEmployees = true;
                    _this.hasComments = true;

                    //_this.comments.length = 0;
                    _.forEach(employeeComments.comments, function (comment) {
                        _this.question.comments.push(comment);
                    });

                    _this.totalComments = employeeComments.total;
                    _this.values = employeeComments.question && employeeComments.question.values;

                    next();
                }, function errorCallback(response) {

                    _this.hasEnoughActiveEmployees = false;
                    next(response);
                });
            });

            async.parallel(commentStream, function (err, data) {
                if (callback)
                    callback(err, data);

                _this.loadingComments = false;
            });
        };

        function prepareParams() {

            var params = {};

            if (_this.dateFromEnabled) {
                params.from = _this.dateFrom;
            }

            if (_this.dateToEnabled) {
                params.to = _this.dateTo;
            }

            params.questionId = _this.question.id;
            params.groupId = _this.groups;

            // Set he current hierarchy for filter the comments
            if (_this.hierarchyId && _this.hierarchyId.length) {
                params.hierarchyId = _this.hierarchyId.join(',');
            }

            if (_this.searchText != null) {
                params.commentText = _this.searchText;
            }

            if (_this.commentsOrder != null) {
                params.sortOrder = _this.commentsOrder;
            }

            if (_this.activedTags) {
                params.tags = _this.activedTags;
            }

            if (_this.activedRatings) {
                params.ratings = _this.activedRatings;
            }
            params.filters = SegmentsFilterService.compileFilterStringFromSegments(_this.filters);

            if (_this.selectedFeebackTypes.length) {
                params.feedbackTypes = _this.selectedFeebackTypes.join(',');
            }

            if (_this.filterByComment) {
                params.sameAuthorAs = _this.filterByComment;
            }

            if (_this.hasCompanyReply) {
                params.hasCompanyReply = true;
            }

            if (_this.hasEmployeeReplies || _this.hasReplies) {
                params.hasReplies = true;
            }

            if (_this.hasConversation) {
                params.hasConversation = true;
            }

            if (_this.classifiedStatus != null) {
                params.classifiedStatus = _this.classifiedStatus;
            }

            if (_this.privated) {
                params.privated = _this.privated;
            }

            if (_this.hasSignature) {
                params.hasSignature = _this.hasSignature;
            }

            if ($stateParams.commentId) {
                params.commentId = $stateParams.commentId;
            }


            return params;
        };

        _this.initialize();

        // Helper functions
        _this.exportToExcel = function () {

            var params = prepareParams();

            _this.exportingExcelReport = true;
            FeedbackReportTypeModal.showCommentsReportModal(params, function () {
                _this.exportingExcelReport = false;
            });
        };

        // TAGS
        _this.toogleTag = function (tag) {
            var index = _this.activedTags.indexOf(tag);
            if (index == -1)
                _this.activedTags.push(tag);
            else
                _this.activedTags.splice(index, 1);

            Analytics.trackEvent('comments', 'filter_by_tag');
            _this.loadComments();
        };

        _this.toogleFeedbackType = function (feedbackType) {
            var index = _this.selectedFeebackTypes.indexOf(feedbackType);
            if (index == -1)
                _this.selectedFeebackTypes.push(feedbackType);
            else
                _this.selectedFeebackTypes.splice(index, 1);
            Analytics.trackEvent('comments', 'filter_by_feedback_type');
            _this.loadComments();
        };

        // TAGS
        _this.toogleRating = function (rating) {
            var index = _this.activedRatings.indexOf(rating);
            if (index == -1)
                _this.activedRatings.push(rating);
            else
                _this.activedRatings.splice(index, 1);
            Analytics.trackEvent('comments', 'filter_by_rating');
            _this.loadComments();
        };

        _this.resetTags = function () {
            _this.activedTags = [];
            _this.loadComments();
        };

        _this.getColorFromRating = function (rating) {
            var leyend = _.find(_this.leyendRatings, function (leyend) {
                return leyend.id === rating;
            });

            return leyend.color;
        };

        _this.setComment = function (selected) {
            _this.selected_comment = selected;
            _this.temp_comment = JSON.parse(JSON.stringify(selected)); // Cerdada (cambiar con angular.copy)
        };

        _this.changePage = function () {
            $location.hash('pagination');
            _this.loadComments(function (err, data) {
                $location.hash('comments');
                anchorSmoothScroll.scrollTo('comments');
            });
        };


        _this.showCalendar = function (e) {
            var datePicker = angular.element(e.target.previousSibling);
            if (datePicker.attr('date-picker-wrapper') === '') {
                datePicker.children().removeClass('hidden');
            }
        };

        //loadData();
        Analytics.trackEvent('comments', 'detail', (!_this.level) && 'top');

        $intercom.trackEvent('dashboard_visited_feedback');
    };
}


const hfFeedbackComments= angular.module('happyForceApp')
    .directive('hfFeedbackComments', function () {
        return {
            template: require('./feedback-comments.html'),
            controller: FeedbackCommentsCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackComments;
