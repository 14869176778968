'use strict';

function AnnouncementCommentPreviewCtrl(Comments, $scope) {
    var _this = this;

    _this.loadComment = function () {
        if (_this.commentIds) {
            var commentId = _this.commentIds[0];
            Comments.getOne(commentId, function (err, comment) {
                _this.comment = comment;
                _this.commentColor = Comments.getFeedbackTypeColor(comment.feedbackType);
            });
        } else {
            delete _this.comment;

        }
    };

    _this.$onInit = function () {
        ['commentIds'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o && _this.comment != null) {
                    return;
                }
                _this.loadComment();
            }, true);
        });
    };
};

const hfAnnouncementCommentsPreview= angular.module('happyForceApp').directive('hfAnnouncementCommentPreview', function () {
    return {
        scope: {
            commentIds: '<'
        },
        replace: true,
        template: require('./announcement-comment-preview.html'),
        controller: AnnouncementCommentPreviewCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfAnnouncementCommentsPreview;
