import angular  from "angular";
export default function Languages(CompanySvrc, AuthSrvc, $localStorage, $translate) {
    'ngInject';

    var languages = [
        { code: 'ar', language: 'Arabic' },
        { code: 'hy', language: 'Armenian' },
        { code: 'az', language: 'Azerbaijani' },
        { code: 'eu', language: 'Basque' },
        { code: 'be', language: 'Belarusian' },
        { code: 'bn', language: 'Bengali' },
        { code: 'bs', language: 'Bosnian' },
        { code: 'br', language: 'Breton' },
        { code: 'bg', language: 'Bulgarian' },
        { code: 'ca', language: 'Catalan' },
        { code: 'ce', language: 'Chechen' },
        { code: 'zh', language: 'Chinese' },
        { code: 'hr', language: 'Croatian' },
        { code: 'cs', language: 'Czech' },
        { code: 'da', language: 'Danish' },
        { code: 'nl', language: 'Dutch (Standard)' },
        { code: 'en', language: 'English' },
        { code: 'et', language: 'Estonian' },
        { code: 'fa', language: 'Farsi' },
        { code: 'fi', language: 'Finnish' },
        { code: 'fr', language: 'French (Standard)' },
        { code: 'fy', language: 'Frisian' },
        { code: 'fur', language: 'Friulian' },
        { code: 'gd', language: 'Gaelic (Scots)' },
        { code: 'gd-IE', language: 'Gaelic (Irish)' },
        { code: 'ka', language: 'Georgian' },
        { code: 'de', language: 'German (Standard)' },
        { code: 'el', language: 'Greek' },
        { code: 'he', language: 'Hebrew' },
        { code: 'hi', language: 'Hindi' },
        { code: 'hu', language: 'Hungarian' },
        { code: 'is', language: 'Icelandic' },
        { code: 'id', language: 'Indonesian' },
        { code: 'ga', language: 'Irish' },
        { code: 'it', language: 'Italian (Standard)' },
        { code: 'ja', language: 'Japanese' },
        { code: 'ko', language: 'Korean' },
        { code: 'lv', language: 'Latvian' },
        { code: 'lt', language: 'Lithuanian' },
        { code: 'mk', language: 'FYRO Macedonian' },
        { code: 'ms', language: 'Malay' },
        { code: 'no', language: 'Norwegian' },
        { code: 'pl', language: 'Polish' },
        { code: 'pt', language: 'Portuguese' },
        { code: 'pt-BR', language: 'Portuguese (Brazil)' },
        { code: 'pa', language: 'Punjabi' },
        { code: 'ro', language: 'Romanian' },
        { code: 'ru', language: 'Russian' },
        { code: 'sr', language: 'Serbian' },
        { code: 'sk', language: 'Slovak' },
        { code: 'sl', language: 'Slovenian' },
        { code: 'es', language: 'Spanish' },
        { code: 'sw', language: 'Swahili' },
        { code: 'sv', language: 'Swedish' },
        { code: 'th', language: 'Thai' },
        { code: 'tr', language: 'Turkish' },
        { code: 'tk', language: 'Turkmen' },
        { code: 'uk', language: 'Ukrainian' },
        { code: 'ur', language: 'Urdu' },
        { code: 've', language: 'Venda' },
        { code: 'vi', language: 'Vietnamese' }
    ];

    var companyName;

    var methods = {};
    methods.list = languages;

    methods.getNameByCode = function (code) {
        var _lang = languages.filter(function (lang) {
            return lang.code === code;
        });

        if (_lang && _lang.length == 1) return _lang[0].language;
        return null;
    };

    methods.getSupportedLanguages = function (callback) {
        CompanySvrc.getCurrentCompanyConfig(function (error, companyConfig) {
            if (error) {
                console.log('Error retrieving supported languages');
                callback(error, null);
            } else {
                var supportedLanguages = companyConfig.supportedLanguages && companyConfig.supportedLanguages.filter(function (lang) {
                    return companyConfig.defaultLanguage != lang;
                }).map(function (lang) {
                    return { code: lang, language: methods.getNameByCode(lang) };
                });
                callback(null, supportedLanguages);
            }
        });

    };

    methods.replaceTokens = function (input, callback) {


        if (input && input.includes('*|COMPANY_NAME|*')) {
            if (companyName) {
                callback(null, input.replaceAll('*|COMPANY_NAME|*', companyName));
            } else {

                CompanySvrc.getUserCompany(function (err, company) {
                    if (err) {
                        companyName = $translate.instant('COMPANY');
                    } else {
                        companyName = company.name;
                    }
                    callback(null, input.replaceAll('*|COMPANY_NAME|*', companyName));
                });

            }
        } else {
            callback(null, input);
        }

    };

    methods.getTranslationFromUserLanguage = function (languageItem, userLanguage) {
        userLanguage = userLanguage || AuthSrvc.getLanguage();
        if (userLanguage && languageItem && languageItem.langValues && languageItem.langValues[userLanguage]) {
            return languageItem.langValues[userLanguage];
        } else if (languageItem && (languageItem.defaultValue || languageItem.defaultValue === '')) {
            return languageItem.defaultValue;
        } else {
            return languageItem;
        }
    };

    methods.appLanguages = [
        { id: 'en', label: $translate.instant('TRANSLATE_EN') },
        { id: 'es', label: $translate.instant('TRANSLATE_ES') },
        { id: 'de', label: $translate.instant('TRANSLATE_DE') },
        { id: 'fr', label: $translate.instant('TRANSLATE_FR') },
        { id: 'it', label: $translate.instant('TRANSLATE_IT') },
        { id: 'pl', label: $translate.instant('TRANSLATE_PL') },
        { id: 'pt-br', label: $translate.instant('TRANSLATE_PT_BR') },
        { id: 'pt', label: $translate.instant('TRANSLATE_PT_PT') },
        { id: 'ru', label: $translate.instant('TRANSLATE_RU') }
    ];

    methods.appLangCodes = ['en', 'es', 'de', 'fr', 'it', 'pl', 'pt-br', 'pt', 'ru'];


    methods.initializeOrGetLangValue = function (langItem, language) {
        if (language) {
            // If we are adding a new language, check if is initialized or add
            if (!langItem.langValues) {
                langItem.langValues = {};
            }
            if (!langItem.langValues[language]) {
                langItem.langValues[language] = '';
            }

            return langItem.langValues[language];
        } else {
            return langItem.defaultValue;
        }
    };

    methods.setLangValue =  function (langItem, value, language) {
        if (language) {
            if (!langItem.langValues) {
                langItem.langValues = {};
            }
            langItem.langValues[language] = value;
        } else {
            langItem.defaultValue = value;
        }
    };

    methods.removeLangValue = function (langItem, language) {
        if (language && langItem.langValues) {
            delete langItem.langValues[language];
        }
    };

    methods.getLangs = function (langItem) {
        return Object.keys(langItem.langValues || {});
    };

    methods.createMultiLanguage = function () {
        return {
            defaultValue: '',
            langValues: {}
        };
    };



    return methods;
}
