'use strict';

function SurveyFeedbackViewCtrl($scope, $translate, Surveys, SegmentsFilterService, Languages, ErrorSvrc) {
    'ngInject';
    var _this = this;

    _this.loading = false;
    _this.questionId = null;
    this.currentCommentPage = 1;
    this.totalComments = 0;

    _this.buildQuestionList = function () {
        _this.questionIds = [];

        _this.questionIds.push({
            id: null,
            label: $translate.instant('SURVEY_FEEDBACK_ALL_QUESTIONS')
        });

        _this.survey.blocks.forEach(function (block) {
            block.questions.forEach(function (question) {


                const translatedQuestion = Languages.getTranslationFromUserLanguage(question.title);
                Languages.replaceTokens(translatedQuestion,function (err, questionTitle) {

                    _this.questionIds.push({
                        id: question.id,
                        label: questionTitle
                    });
                });


            });
        });
    };

    _this.loadFeedback = function () {
        _this.feedbackData = [];

        if (_this.survey) {
            _this.loading = true;

            var params = {};

            params.questionId = _this.questionId;


            if (_this.hierarchyIds && _this.hierarchyIds.length) {
                params.hierarchyId = _this.hierarchyIds;
            };


            if (_this.segmentIds && _this.segmentIds.length) {
                params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.segmentIds);
            }


            if (_this.groupIds && _this.groupIds.length) {
                params.groupId = _this.groupIds;
            };

            if (_this.questionFilters)
                params.questionResults = _this.questionFilters.join(',');


            params.fromComment = (_this.currentCommentPage - 1) * 10;
            params.toComment = 10;


            if (_this.fromDateEnabled)
                params.from = _this.fromDate;

            if (_this.toDateEnabled)
                params.to = _this.toDate;

            if (_this.roundId)
                params.roundId = _this.roundId;


            Surveys.feedback.get(_this.survey.id, params, function (err, comments) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    _this.feedbackData = comments;

                    console.log('Result es ' + comments.result.length);
                    _this.totalComments = comments.total;
                }
            });
        }
    };


    this.$onInit = function () {


        var filterArgs = [
            'survey',
            'roundId',
            'heatmapGroupBy',
            'hierarchyIds',
            'segmentIds',
            'groupIds',
            'questionFilters',
            'questionId',
            'fromDateEnabled',
            'fromDate',
            'toDateEnabled',
            'toDate',
            'currentCommentPage'];

        filterArgs.forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (newVal, oldValue) {

                if (typeof newVal === 'undefined') return;
                if (newVal != oldValue || !_this.feedbackData) {
                    _this.loadFeedback();
                }

                if (_this.survey && !_this.questionIds) {
                    _this.buildQuestionList();
                }

            }, true);
        });

    };


};

const hfSurveyFeedbackView = angular.module('happyForceApp')
    .directive('hfSurveyFeedbackView', function () {
        return {
            scope: {
                survey: '=',
                roundId: '=',
                groupIds: '=',
                segmentIds: '=',
                hierarchyIds: '=',
                questionFilters: '=',
                fromDateEnabled: '=',
                fromDate: '=',
                toDateEnabled: '=',
                toDate: '='
            },
            template: require('./survey_feedback_view.html'),
            controller: SurveyFeedbackViewCtrl,
            controllerAs: 'sfv',
            bindToController: true
        };
    });

export default hfSurveyFeedbackView;
