import * as angular from 'angular';
import 'angular-cookies';
import 'restangular';
import * as oclazyload from 'oclazyload';

//import 'angular-file-upload';
import {GoogleCharts} from 'google-charts';
import 'ng-file-upload';
import 'angular-translate';
import 'angular-datepicker';
import 'angular-ui-bootstrap';
import 'angular-moment';
import 'angular-daterangepicker/js/angular-daterangepicker';
import 'angular-animate';
import 'highcharts-ng';
import 'angular-ui-router';
import 'ngstorage';
import 'angular-intercom';
import 'angular-ui-select/select';
import 'angular-sanitize';
import 'bootstrap';
import 'jquery';
import 'summernote';
import 'angular-summernote/dist/angular-summernote';
import 'angular-ui-switch';
import 'angularjs-toaster'
import 'angular-bootstrap-colorpicker';
import 'angular-google-analytics';
import 'checklist-model';
import 'angular-PubSub'
import 'angularfire';
import 'angular-xeditable';
import 'angular-footable';
import 'angularjs-social-login';
import 'angular-read-more';
import 'angular-md5';
import 'angular-ui-tree';
import 'angular-trix';
import 'angular-ui-calendar/src/calendar.js';
import 'angular-drag-and-drop-lists';
import 'ng-rollbar';
import 'sweetalert';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'daterangepicker/daterangepicker';
import 'easy-pie-chart/src/angular.directive'
import configureLanguage from './config/i18n.config';
import routes from './config/routes';
import Trix from 'trix'

import i18nTranslation from './config/i18next.config';
import rzSlider from 'angularjs-slider';


/* Use to initialize select2 */
import select2 from 'select2';


angular.module('angular-cron-jobs', []);

var app = angular.module('happyForceApp', [
    'ngCookies', // imported
    'restangular', // imported
    oclazyload, // imported
    'ngFileUpload', // imported
    'pascalprecht.translate',
    'easypiechart',
    'datePicker',
    'ui.bootstrap',
    'angularMoment',
    'daterangepicker',
    'ngAnimate',
    'highcharts-ng',
    'ngStorage',
    'ui.router',
    'ngIntercom',
    // 'ui.slimscroll',
    'ui.select',
    'ngSanitize',
    'summernote',
    'uiSwitch',
    'toaster',
    'colorpicker.module',
    'angular-google-analytics',
    'tandibar/ng-rollbar',
    //'angular-timezone-selector',
    'angular-cron-jobs',
    'checklist-model',
    'PubSub',
    'firebase',
    // 'rzModule',
    'xeditable',
    'ui.footable',
    'socialLogin',
    //'angular-country-select',
    'hm.readmore',
    'angular-md5',
    'ui.tree',
    'angularTrix',
    'ui.calendar',
    'dndLists',
    'rzModule'
]);


app.constant('Settings', {
    APIUrl: ( API_URL + '/api/v1'),
    angularMomentConfig: {
        timezone: 'Europe/Madrid' // e.g. 'Europe/London'
    },
    defaultDateFormat: 'MMM DD, YYYY',
    defaultMonthDateFormat: 'MMM YYYY',
    defaultQuarterDateFormat: '[Q]Q-YYYY',
    defaultYearDateFormat: 'YYYY',
    defaultTimeFormat: 'hh:mma',
    env: 'prod',
    apiDateFormat: 'DDMMYYYY',
    announcementSectionsConfig: {
        celebrationSectionId: '63ad5dbdaee16935acb036c5',
        commentReplySectionId: '6405dd0386501193da23207b'
    }
}).config(['Settings', function (Settings) {

    Settings.env = Settings.APIUrl.indexOf('api.myhappyforce') > -1
        ? 'production' : (Settings.APIUrl.indexOf('api-staging.myhappyforce') > -1 ? 'stage' : 'local');
}]).config(['$intercomProvider', function ($intercomProvider) {
    $intercomProvider
        .appID('xp9iy0t4');

    // you can include the Intercom's script yourself or use the built in async loading feature
    $intercomProvider
        .asyncLoading(true);

    swal.setDefaults({ confirmButtonColor: '#0000' });
}]).config(['RollbarProvider', 'Settings', function (RollbarProvider, Settings) {
    RollbarProvider.init({
        accessToken: Settings.env === 'production' ? 'af7bdee56bac4c50b467efa3a497bde5' : '3f81c1dc1194414e9e5b2ca9563ff21e',
        captureUncaught: true,
        rollbarJsUrl: 'https://cdnjs.cloudflare.com/ajax/libs/rollbar.js/2.19.4/rollbar.min.js',
        payload: {
            environment: Settings.env,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: '/*@@srcVersion@@*/',
                    guess_uncaught_frames: true
                }
            }
        }
    });
}]).config(['AnalyticsProvider',function (AnalyticsProvider) {
    // Add configuration code as desired
    AnalyticsProvider.setAccount('UA-93033315-2').logAllCalls(true);  //UU-XXXXXXX-X should be your tracking code
}])
    .config(['$localStorageProvider', '$sessionStorageProvider',function ($localStorageProvider, $sessionStorageProvider) {
        'ngInject';
        $localStorageProvider.setKeyPrefix('HappyForce-');
        $sessionStorageProvider.setKeyPrefix('HappyForce-');
    }])
    .config(function ($logProvider) {
        $logProvider.debugEnabled(localStorage.getItem('debug') !== 'undefined' ? true : false);
    })
    .config(function (socialProvider) {
        socialProvider.setGoogleKey('309293299735-obs30o6vc0dfi93ss47du91ftu7hiv9n.apps.googleusercontent.com');
    }).config(function ($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'http://localhost:3000/**'
        ])

    });


// Configure the languages
app.config(configureLanguage);

// Configure the routes
app.config(routes);

app.config(function($locationProvider) {
    $locationProvider.html5Mode(true);
});


// Require inspinia
require('./inspinia');




export default app;
