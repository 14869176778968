'use strict';

import hfAnnouncementCommentsPreview
    from 'root/app/components/announcements/components/announcement-preview/components/announcement-comment-preview/announcement-comment-preview.component';


function AnnouncementPreview(Announcements, ErrorSvrc, $scope) {
    var _this = this;

    _this.loadingAnnnouncement = false;

    _this.edit = function () {
        if (_this.onAnnouncementEdit) {
            _this.onAnnouncementEdit(_this.announcement.id);
        }
    };


    _this.clone = function () {
        if (_this.onAnnouncementClone) {
            _this.onAnnouncementClone(_this.announcement.id);
        }
    };

    _this.swapArchivedStatus = function () {
        if (_this.onAnnouncementSwapArchivedStatus) {
            _this.onAnnouncementSwapArchivedStatus(_this.announcement.id);
        }
    };

    _this.changeSection = function () {
        if (_this.onAnnouncementChangeSection) {
            _this.onAnnouncementChangeSection(_this.announcement.id);
        }
    };

    _this.delete = function () {
        if (_this.onAnnouncementDelete) {
            _this.onAnnouncementDelete(_this.announcement.id);
        }
    };

    this.updateCategories = function () {
        if (_this.announcement.categoryIds) {
            Announcements.getCategories(function (err, categories) {
                if (err)
                    ErrorSvrc.showErrorModal(error);
                else {
                    _this.categories = _this.announcement.categoryIds.map(function (id) {
                        return categories.plain().find(function (element) {
                            return element.id === id;
                        });
                    });
                }
            });
        } else {
            delete _this.categories;
        }
    };

    this.loadSectionData = function () {
        if (_this.announcement && _this.announcement.editable) {
            _this.canEditAnnouncements = true;

            // Load section to check if we can clone announcements of it
            Announcements.getSection(_this.announcement.sectionId, {}, function (error, sectionData) {
                if (error) {
                    ErrorSvrc.showErrorModal(error);
                } else {

                    // Check if we can clone announcements
                    _this.canCloneAnnouncements = sectionData.publishingCapable;

                    // Check if we can delete announcements
                    _this.canDeleteAnnouncements = sectionData.deletionCapable;

                    // Check if we can archive announcements
                    _this.canArchiveAnnouncements = sectionData.archivableCapable;

                    // Check if we can change section
                    _this.canChangeSection = sectionData.publishingCapable;

                }
            });
        } else {
            _this.canEditAnnouncements = _this.announcement.editable;
            _this.canCloneAnnouncements = false;
            _this.canDeleteAnnouncements = false;
            _this.canChangeSection = false;
        }
    };



    this.loadSelectedAnnouncement = function () {
        delete _this.categories;
        if (_this.announcementId) {
            _this.loadingAnnnouncement = true;
            Announcements.getOne(
                _this.announcementId,
                function (err, announcement) {
                    _this.loadingAnnnouncement = false;
                    if (err) {
                        ErrorSvrc.showErrorModal(response);
                    } else {
                        _this.announcement = announcement;
                        _this.updateCategories();
                        _this.loadSectionData();
                    }
                }
            );



        } else {
            delete _this.announcement;
        }
    };

    _this.$onInit = function () {
        ['announcementId'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                _this.loadSelectedAnnouncement();
            }, true);
        });

        _this.loadSelectedAnnouncement();
    };
};

//Directive used to set metisMenu and minimalize button
const hfAnnouncementPreview= angular.module('happyForceApp').directive('hfAnnouncementPreview', function () {
    return {
        scope: {
            announcementId: '<',
            onAnnouncementEdit: '<',
            onAnnouncementClone: '<',
            onAnnouncementDelete: '<',
            onAnnouncementChangeSection: '<',
            onAnnouncementSwapArchivedStatus: '<'
        },
        replace: true,
        template: require('./announcement-preview.html'),
        controller: AnnouncementPreview,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfAnnouncementPreview;
