const hftokenreplace = function (Languages) {

    'ngInject';
    var hash = [];
    var loading = [];

    function asyncLoad(input) {
        if (input) {
            if (hash[input]) {
                return hash[input];
            } else {
                if (loading[input]) {
                    return;
                }

                loading[input] = true;

                return Languages.replaceTokens(input, function (err, name) {
                    if (err) {
                        hash[input] = '?';
                    } else {
                        hash[input] = name;
                    }
                    loading[input] = false;

                    //$rootScope.$apply();
                });
            }
        } else {
            console.error('Wrong input id for hierarchy name');
        }

        return input;
    }

    asyncLoad.$stateful = true;
    return asyncLoad;
}

export default hftokenreplace

