import routingConfig from '../../config/routingConfig';
import * as Sentry from '@sentry/browser';

export default function AuthSrvc(Upload, Restangular, Settings, $localStorage, $intercom, $sessionStorage, Analytics, Rollbar, $translate) {
    'ngInject';
    var CACHE_KEY = 'happyForceUser';
    var accessLevels = routingConfig.accessLevels;
    var userRoles = routingConfig.userRoles;
    var defaultUser = cleanUser();

    function cleanUser() {
        return {
            username: '',
            email: '',
            role: userRoles.PUBLIC.title,
            roleBitMask: userRoles.PUBLIC.bitMask,
            sessionId: ''
        };
    }

    function setDefaultHeader(sessionId) {
        Restangular.setDefaultHeaders({
            SessionId: sessionId
        });
    }

    function changeUser(user, sessionId) {
        _.assign(getStorageUser(), user);

        if (sessionId)
        {user.sessionId = sessionId;}

        saveStorageUser(user);

        if (sessionId && user.language)
        {$translate.use(user.language);}


        // saveUserLang(user);
        setDefaultHeader(getStorageUser().sessionId);
    }

    function setRoleBitmaskFromRoleName(user) {
        for (var roleIndex in userRoles) {
            if (userRoles[roleIndex].title == user.role) {
                user.roleBitMask = userRoles[roleIndex].bitMask;
                break;
            }
        }
    }

    function getStorageUser() {
        var cookieUser = null;
        cookieUser = $localStorage[CACHE_KEY];

        if (typeof cookieUser === 'undefined' || cookieUser === null) {
            return defaultUser;
        }

        return cookieUser;
    }

    function saveStorageUser(user) {
        $localStorage[CACHE_KEY] = user;
    }


    function removeStorageUser() {
        delete $localStorage[CACHE_KEY];
        defaultUser = cleanUser();
        if ($sessionStorage && $sessionStorage.filters)
        {delete $sessionStorage.filters;}
    }

    function invalidate() {
        // Remove the cookie in any case
        changeUser(defaultUser, '');
        removeStorageUser();
    }

    var salida = {
        authorize: function (accessLevel, roleBitMask) {
            if (roleBitMask === undefined) {
                roleBitMask = getStorageUser().roleBitMask;
            }

            return accessLevel.bitMask & roleBitMask;
        },

        isLoggedIn: function () {
            return getStorageUser().role == userRoles.ADMIN.title ||
                getStorageUser().role == userRoles.ADVISOR.title ||
                getStorageUser().role == userRoles.RESPONSIBLE.title ||
                getStorageUser().role == userRoles.MANAGER.title;
        },

        register: function (user, success, error) {
            Restangular.one('company').post('register', user).then(function (user) {
                success(user);
            }, function errorCallback(response) {

                error(response);
            });
        },

        forget: function (user, success, error) {
            Restangular.one('company').post('forget', user).then(function (user) {
                Analytics.trackEvent('login', 'forget', 'success');
                success(user);
            }, function errorCallback(response) {

                Analytics.trackEvent('login', 'forget', 'failure');
                error(response);
            });
        },

        login: function (user, success, error) {
            Restangular.one('company').post('login', user).then(function (user) {
                setRoleBitmaskFromRoleName(user.companyUser);
                changeUser(user.companyUser, user.sessionId);
                Analytics.trackEvent('login', 'login', 'success');
                Analytics.set('&uid', user.companyUser.email);
                console.log('x1', JSON.stringify(user))
                success(user);
            }, function errorCallback(response) {

                console.log('x2', JSON.stringify(response))
                Analytics.trackEvent('login', 'login', 'failure');
                if (!response.noLogout) {removeStorageUser();}
                error(response);
            });
        },

        me: function (callback) {
            Restangular.one('company').one('user').one('me').get().then(function (user) {
                setRoleBitmaskFromRoleName(user.companyUser);
                changeUser(user.companyUser, user.sessionId);
                callback(null, user);
            }, function errorCallback(err) {
                callback(err);
            });
        },

        sendAccessLink: function (data, success, error) {
            Restangular.one('company').post('sendAccessLink', data).then(function (user) {
                Analytics.trackEvent('login', 'sendAccessLink', 'success');
                success(data);
            }, function errorCallback(response) {

                Analytics.trackEvent('login', 'sendAccessLink', 'failure');
                error(response);
            });
        },

        logout: function (success, error) {
            // Remove the cookie in any case
            Restangular.one('company').post('logout', getStorageUser()).then(function () {
                success();
                $intercom.shutdown();
                Sentry.setUser(null);
                Analytics.trackEvent('login', 'logout', 'success');
            }, function errorCallback(response) {

                Analytics.trackEvent('login', 'logout', 'failure');
                error(response);
            });

            invalidate();
        },

        invalidate: function () {
            invalidate();
        },

        sessionId: function () {
            return getStorageUser().sessionId;
        },

        getUserName: function () {
            return getStorageUser().name;
        },

        getUserId: function () {
            return getStorageUser().id;
        },

        isPublic: function (urlString) {
            var publicURL = ['access', 'forgot', 'onboarding', 'register', 'require', 'employeesent'];
            return new RegExp(publicURL.join('|')).test(urlString);
        },

        updateUser: function (userUpdate, success, error) {
            // Get the data for the current hierarchy
            Restangular.one('company').one('user').one('info').customPUT(userUpdate).then(function (updatedUser) {
                setRoleBitmaskFromRoleName(updatedUser.companyUser);
                var user = updatedUser.companyUser;

                changeUser(user, updatedUser.sessionId);

                $intercom.update({
                    email: user.email,
                    role: user.role,
                    name: user.name
                });

                Rollbar.configure({
                    payload: {
                        user: {
                            username: user.name,
                            email: user.email,
                            role: user.role
                        }
                    }
                });


                Sentry.setUser({
                    id: user.id,
                    email:  user.email
                });


                success(user);
            }, function errorCallback(response) {

                error(response);
            });
        },

        userHasRole: function (roles) {
            var exists = false;
            function checkUserRole(role) {
                return getStorageUser().role === role || getStorageUser().role === 'ADVISOR'; // ADVISORS ALWAYS!!
            }

            if (roles instanceof Array) {
                roles.forEach(function (role) {
                    if (exists) {return;}
                    exists = checkUserRole(role);
                });
            } else {
                exists = checkUserRole(roles);
            }

            return exists;
        },

        setUserImage: function (file, success, progress, error) {
            var upload = Upload.upload({
                url: Settings.APIUrl + '/company/user/image',
                headers: {
                    SessionId: getStorageUser().sessionId
                },
                file: file
            }).progress(function (evt) {
                progress(evt.loaded, evt.total);
            }).success(function (updatedUser, status, headers, config) {
                changeUser(updatedUser, getStorageUser().sessionId);
                success(updatedUser);
            }).error(function () {
                error();
            });
        },

        hasUserImage: function () {
            return typeof getStorageUser().profileImagePath !== 'undefined' && getStorageUser().profileImagePath !== null;
        },

        getUserImage: function () {
            var imagePath = getStorageUser().profileImagePath;
            if (!imagePath) {
                // return "https://www.placehold.it/200x150/EFEFEF/AAAAAA&text=" + getStorageUser().name[0];
                return 'https://dummyimage.com/35x35&text=' + (getStorageUser().name ? getStorageUser().name[0] : 'HF');
            }

            return imagePath;
        },

        getAppActivationToken: function (callback) {
            Restangular.one('company').one('user').one('appToken').get().then(function (result) {
                callback(null, result);
            }, function errorCallback(err) {
                callback(err, null);
            });
        },
        checkMailAndUserType: function (email, success, error) {
            Restangular.one('company').one('check').one(email).get().then(function (userType) {
                success(userType);
            }, function errorCallback(response) {
                error(response);
            });
        },

        getHpToken: function (code, callback) {
            Restangular.one('company').one('hptoken').one(code).get().then(function (result) {
                callback(null, result);
            }, function (err) {
                callback(err);
            });
        },

        sendEmployeeNewCode: function (email, success, error) {
            Restangular.one('employee').one('resend').one(email).post().then(function () {
                success();
            }, function errorCallback(response) {

                error(response);
            });
        },

        getLanguage: function () {
            return getStorageUser().language || 'en';
        },

        accessLevels: accessLevels,
        userRoles: userRoles,
        user: function () {
            return getStorageUser();
        },

        isADMIN: function () {
            return getStorageUser().role === 'ADMIN';
        },
        isADVISOR: function () {
            return getStorageUser().role === 'ADVISOR';
        },
        isPUBLIC: function () {
            return getStorageUser().role === 'PUBLIC';
        },
        isRESPONSIBLE: function () {
            return getStorageUser().role === 'RESPONSIBLE';
        },
        isMANAGER: function () {
            return getStorageUser().role === 'MANAGER';
        }
    };

    return salida;
}
